import { Component, Prop, Vue } from 'vue-property-decorator';
import format from 'date-fns/format';

import template from './date-range.vue';

@Component({
  mixins: [template]
})
export default class DateRange extends Vue {
  @Prop() public from!: Date;

  @Prop() public to!: Date;

  @Prop() public format!: string;

  public get toDateFormatted(): string {
    return this.to ? format(this.to, this.format) : 'Now';
  }
}
