import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations } from 'vuex';

import Avatar from '@/components/avatar';
import { OPEN_MODAL } from '@/store/mutation-types';

import template from './general.vue';

@Component({
  components: {
    Avatar
  },
  methods: {
    ...mapMutations({
      openModal: `modal/${OPEN_MODAL}`
    })
  },
  mixins: [template],
  props: {
    profile: Object
  }
})
export default class General extends Vue {}
