import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations } from 'vuex';

import dateRange from '@/components/date-range';
import list from '@/components/list';
import { OPEN_MODAL } from '@/store/mutation-types';
import template from './certifications.vue';

@Component({
  components: {
    dateRange,
    list
  },
  methods: {
    ...mapMutations({
      openModal: `modal/${OPEN_MODAL}`
    })
  },
  mixins: [template],
  props: {
    certifications: Array
  }
})
export default class Certifications extends Vue {}
