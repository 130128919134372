import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations } from 'vuex';

import list from '@/components/list';
import { OPEN_MODAL } from '@/store/mutation-types';

import template from './causes.vue';

@Component({
  components: {
    list
  },
  methods: {
    ...mapMutations({
      openModal: `modal/${OPEN_MODAL}`
    })
  },
  mixins: [template],
  props: {
    causes: Array
  }
})
export default class Causes extends Vue {}
