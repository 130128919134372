import { Component, Prop, Vue } from 'vue-property-decorator';
import { LIST_DEFAULT_DISPLAYED_COUNT } from '@/constants';

import template from './list.vue';

@Component({
  mixins: [template]
})
export default class List extends Vue {
  @Prop() public items!: any[];

  @Prop() public subheader!: string;

  @Prop() public twoLine!: boolean;

  @Prop() public threeLine!: boolean;

  public get itemsToDisplay() {
    return this.items.slice(0, this.displayedCount);
  }

  public displayedCount: number = LIST_DEFAULT_DISPLAYED_COUNT;

  public hasMoreButton: boolean = true;

  public showMoreOrLess() {
    this.displayedCount =
      this.displayedCount === this.items.length
        ? (this.displayedCount = LIST_DEFAULT_DISPLAYED_COUNT)
        : this.items.length;
  }

  public created() {
    this.hasMoreButton = this.items.length > LIST_DEFAULT_DISPLAYED_COUNT;
  }
}
