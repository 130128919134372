import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import General from '@/components/general';
import Experiences from '@/components/experiences';
import Educations from '@/components/educations';
import Skills from '@/components/skills';
import Projects from '@/components/projects';
import Certifications from '@/components/certifications';
import Causes from '@/components/causes';
import { USER_LOGOUT } from '@/store/action-types';
import { OPEN_MODAL } from '@/store/mutation-types';

import template from './home.vue';

@Component({
  components: {
    General,
    Experiences,
    Educations,
    Skills,
    Projects,
    Certifications,
    Causes
  },
  computed: {
    ...mapGetters({
      causes: 'cause/causes',
      certifications: 'certification/certifications',
      educations: 'education/educations',
      experiences: 'experience/experiences',
      profile: 'profile/profile',
      projects: 'project/projects',
      skills: 'skill/skills'
    })
  },
  methods: {
    ...mapActions({
      logout: `user/${USER_LOGOUT}`
    }),
    ...mapMutations({
      openModal: `modal/${OPEN_MODAL}`
    })
  },
  mixins: [template]
})
export default class Home extends Vue {}
