import { Skill } from '@/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';

import template from './skills.vue';

@Component({
  mixins: [template]
})
export default class Skills extends Vue {
  @Prop() public skills!: Skill[];
}
